<template>
  <div class="home-container">
    <div class="calender-box">
      <Calendar
        ref="Calendar"
        :markDateMore="arr"
        v-on:isToday="clickToday"
        :sundayStart="true"
        :textTop="['日', '一', '二', '三', '四', '五', '六']"
        v-on:choseDay="clickDay"
        v-on:changeMonth="changeDate"
      ></Calendar>
      <div class="calendar-today" @click="toToday">今天</div>
    </div>
    <div class="admin-cantainer">
      <el-form
        :inline="true"
        :model="filterForm"
        class="filter-form"
        v-if="showFilter"
      >
        <el-form-item style="margin-right: 50px">
          <div class="filter-form-week">{{ selectedDate }}</div>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="全部"
            v-model="filterForm.status"
            class="filter-form-status"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in statusConf"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            class="filter-form-status"
            placeholder="教室号"
            v-model="filterForm.classroomNum"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="课程名称"
            v-model="filterForm.name"
            class="filter-form-status"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-form-btn" @click="clickSearch"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>

      <el-empty
        v-if="dateList.indexOf(nowDate) == -1"
        class="empty-status"
        :image="
          loading
            ? 'http://rts-1251489922.cos.ap-shanghai.myqcloud.com/loadingAnim.gif'
            : 'http://rts-1251489922.cos.ap-shanghai.myqcloud.com/img/1630547660547.png'
        "
        :description="loading ? '加载中' : '暂无课程'"
      ></el-empty>

      <div v-else>
        <!-- 卡片组件 -->
        <div v-for="(item, index) in showList" :key="index">
          <CurriculumCard
            class="card-box"
            v-if="nowDate == item.belonging_day"
            :content="item"
          >
          </CurriculumCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "@/components/calendar/index";
import { formateDate } from "@/utils/common.js";
import CurriculumCard from "@/components/curriculumCard/index";
import {
  getClassRoom,
  getanswering,
  getBindInfo,
  getCourseConfig,
} from "@/api/api.js";
export default {
  name: "Home",
  components: { Calendar, CurriculumCard },
  data() {
    return {
      /*
      **arr中默认的数据格式
      {
        date: '2021/08/31',
        className: "mark1",
      },*/
      arr: [], // 日历下有点的日期
      // 班课参数
      classClass: {
        start_date: "", // 请求班课开始时间，上个月 后7天 '2021-08-15'
        end_date: "", // 请求班课结束时间  下个月 前7天 '2021-10-15'
        teacher:
          this.$store.state.role.id == 1 ? this.$store.state.userInfo.id : null, // 老师id
        // teacher:'598503'
      },
      // 答疑课参数
      answering: {
        start_date: "", // 请求班课开始时间，上个月 后7天 '2021-08-15'
        end_date: "", // 请求班课结束时间  下个月 前7天 '2021-10-15'
        teacher:
          this.$store.state.role.id == 1 ? this.$store.state.userInfo.id : null, // 老师id
      },
      nowDate: formateDate("YYYY-mm-dd", new Date()), // 当前选中日期日期
      tableList: [], // 右侧列表展示
      temp: [], //中转数组，所有数据处理完成后统一渲染
      idList: [], // 所有请求数据的id 用于去重
      dateList: [], // 所有有数据的日期，用于校验当天是否存在课程
      allData: [], // 所有课程 答疑课+班课
      statusConf: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "进行中",
        },
        {
          id: 3,
          name: "未开始",
        },
        {
          id: 4,
          name: "已结束",
        },
      ],
      filterForm: {
        status: null,
        classroomNum: null,
        name: null,
      },
      roomFilter: {
        status: null,
        classroomNum: null,
        name: null,
      },
      loading: false,
    };
  },
  computed: {
    selectedDate() {
      if (!this.nowDate) {
        return this.formatDate(new Date());
      }
      return this.formatDate(new Date(this.nowDate));
    },
    showFilter() {
      return this.$store.state.role.id === 3;
    },
    showList() {
      if (!this.tableList) {
        return [];
      }
      if (!this.showFilter) {
        return this.tableList;
      }
      // 筛选
      let res = this.tableList.filter((item) => {
        // 筛选状态
        if (this.roomFilter.status && this.roomFilter.status != 1) {
          let date = new Date().getTime();
          // 进行中
          if (
            this.roomFilter.status == 2 &&
            (item.class_time_start > date || item.class_time_end < date)
          ) {
            return false;
          }
          // 未开始
          else if (
            this.roomFilter.status == 3 &&
            item.class_time_start < date
          ) {
            return false;
          }
          // 已结束
          else if (this.roomFilter.status == 4 && item.class_time_end > date) {
            return false;
          }
        }
        if (this.roomFilter.classroomNum) {
          if (this.roomFilter.classroomNum != item.serial) {
            return false;
          }
        }
        if (
          this.roomFilter.name &&
          item.course_name.search(this.roomFilter.name) == -1
        ) {
          return false;
        }
        return true;
      });
      return res;
    },
  },
  created() {
    // console.log(this.nowDate, '当前日期', this.arr)
    // this.tabGetPost()
  },
  mounted() {
    // 默认跳转到今天，选中今天
    this.toToday();
  },
  methods: {
    clickSearch() {
      this.roomFilter = JSON.parse(JSON.stringify(this.filterForm));
    },
    formatDate(date) {
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      return `${date.getMonth() + 1}月${date.getDate()}日 ${
        weekArr[date.getDay()]
      }`;
    },
    // 点击某天或跳转月份
    clickToday() {
      // console.log("跳到了本月今天", data); // 跳到了本月
    },
    // 点击某天
    clickDay(data) {
      // console.log("选中了", data); // 选中某天
      // console.log(this.format(0, data))
      // 设置选中的天，用域判断是否显示数据
      this.nowDate = formateDate("YYYY-mm-dd", data);
    },
    // 左右切换月份
    changeDate(data) {
      console.log("左右点击切换月份", data); // 左右点击切换月份
      this.tabGetPost(data);
    },
    // 点击今天
    toToday() {
      let date = new Date();
      let today = formateDate("YYYY-mm-dd", date);
      this.$refs.Calendar.ChoseMonth(today); // 跳到当天
    },
    // 请求数据 答疑课 + 班课
    getDataList() {
      this.loading = true;
      Promise.all([getClassRoom(this.classClass), getanswering(this.answering)])
        .then((result) => {
          // 班课返回值
          let classRoom = [...result[0].data.data];
          // 答疑课返回值
          let answering = [...result[1].data.data];
          // 所有数据合并
          let allData = [...classRoom, ...answering];
          // 如果是教务老师，那么通过批量搜索筛选
          if (this.$store.state.role.id === 2) {
            let courseIds = allData.map((item) => {
              return item.course_id;
            });
            let cids = {};
            courseIds.forEach((item) => {
              cids[item] = item;
            });
            courseIds = Object.keys(cids);
            if (courseIds.length > 0) {
              // 获取批量信息
              getCourseConfig({ course_id: courseIds }).then((res) => {
                // console.log(res);
                let cConf = res.data.data;
                // 过滤所有班主任为当前用户的课程id
                let myCourses = cConf
                  .filter((item1) => {
                    return (
                      Array.isArray(item1.region_assistant) &&
                      item1.region_assistant.some((item2) => {
                        return (
                          item2.assistant_id == this.$store.state.userInfo.id
                        );
                      })
                    );
                  })
                  .map((item) => item.course_id);
                this.allData = allData.filter((item) => {
                  return myCourses.some((item1) => item1 == item.course_id);
                });
                console.log(this.allData);
                let classroomIds = this.allData.map((item) => item.id);
                this.getBindInfo(classroomIds);
              });
            } else {
              this.allData = allData;
              let classroomIds = [];
              for (let item of this.allData) {
                classroomIds.push(item.id);
              }
              this.getBindInfo(classroomIds);
            }
          } else {
            this.allData = allData;
            let classroomIds = [];
            for (let item of this.allData) {
              classroomIds.push(item.id);
            }
            this.getBindInfo(classroomIds);
          }
        })
        .catch(() => {
          this.$message.error("服务器响应超时，请刷新当前页");
        });
    },

    // 数据处理
    arrangementData(allData) {
      console.log(this.$store.state.role);
      // let tempArr = []
      for (let value of allData) {
        let obj = {
          date: value.attends_date || this.format(0, Number(value.date)),
          className: "mark1",
        };
        // 列表数据处理，传给卡片组件
        let dataObj = {
          id: value.id,
          // 讲义名称 有就显示讲义名，没有展示课程名
          handout_name: value.handout_name ? value.handout_name : value.name,
          // 所属哪天用于判断是否显示 YYYY-mm-dd
          belonging_day:
            value.attends_date ||
            formateDate(
              "YYYY-mm-dd",
              Number(value.date) + Number(value.start_time) * 1000
            ),
          // 课程名称
          name: value.name,
          // 所属课程
          course_name: value.course_name,
          // 拓客云的serial 用于去上课获取上课地址
          serial: value.talk_cloud_info?.serial,
          teacher_pwd: value.talk_cloud_info?.teacher_pwd,
          assistant_pwd: value.talk_cloud_info?.assistant_pwd,
          patrol_pwd: value.talk_cloud_info?.patrol_pwd,
          class_time_text: value.start_text
            ? `${this.format(3, value.attends_date)} ${value.start_text}`
            : formateDate(
                "YYYY/mm/dd HH:MM",
                Number(value.date) + Number(value.start_time) * 1000
              ), // 上课时间 字符串
          // 课程时长
          course_duration: "",
          // 上课时间 时间戳毫秒
          class_time_start: value.attends_date_time
            ? (Number(value.attends_date_time) + Number(value.start_time)) *
              1000
            : Number(value.date) + Number(value.start_time) * 1000,
          // 下课时间 时间戳毫秒
          class_time_end: value.attends_date_time
            ? (Number(value.attends_date_time) + Number(value.end_time)) * 1000
            : Number(value.date) + Number(value.end_time) * 1000,
          now_time: new Date().getTime(), // 当前时间
          // stu_num: '111', // 学生人数
          // 课程状态： 0 未上课  1 上课中  2 教学报告
          status: value.attends_date_time
            ? this.isCurrStatus(
                (Number(value.attends_date_time) + Number(value.start_time)) *
                  1000,
                (Number(value.attends_date_time) + Number(value.end_time)) *
                  1000,
                new Date().getTime()
              )
            : this.isCurrStatus(
                Number(value.date) + Number(value.start_time) * 1000,
                Number(value.date) + Number(value.end_time) * 1000,
                new Date().getTime()
              ),
        };
        if (this.idList.indexOf(value.id) == -1 && value.platform == 1) {
          // 日历添加点
          this.arr.push(obj);
          // 所有班课卡片数据数组
          this.temp.push(dataObj);
          // 把每个课程id存起来校验去重条件
          this.idList.push(value.id);
          // 所有课程日期
          this.dateList.push(dataObj.belonging_day);
        }
        this.tableList = this.temp;
      }
    },
    // 请求讲义接口
    getBindInfo(classroomIds) {
      this.loading = false;
      let data = {
        classroom: classroomIds,
      };
      getBindInfo(data).then((res) => {
        if (res.data.code === 0) {
          let allData = this.allData;
          // 匹配课节id添加讲义名
          for (let jyitem of res.data.data) {
            for (let allItem of allData) {
              if (jyitem.classroom === allItem.id) {
                allItem.handout_name = jyitem.handout_name;
              }
            }
          }
          // 整理数据
          this.arrangementData(allData);
        }
      });
    },
    // 获取答疑课接口
    getanswering() {
      getanswering(this.answering).then((res) => {
        let resData = res.data;
        if (resData.code === 0) {
          let data = resData.data;
          // 日历添加点
          for (let value of data) {
            let obj = {
              date: this.format(0, Number(value.date)),
              className: "mark1",
            };
            // console.log(value, 'value')
            // 列表数据处理，传给卡片组件
            let dataObj = {
              id: value.id,
              // 所属哪天用于判断是否显示 YYYY-mm-dd
              belonging_day: formateDate(
                "YYYY-mm-dd",
                Number(value.date) + Number(value.start_time) * 1000
              ),
              name: value.name, // 课程名称
              serial: value.talk_cloud_info?.serial, // 拓客云的serial 用于去上课获取上课地址
              teacher_pwd: value.talk_cloud_info?.teacher_pwd,
              assistant_pwd: value.talk_cloud_info?.assistant_pwd,
              patrol_pwd: value.talk_cloud_info?.patrol_pwd,
              course_name: value.course_name, // 所属课程
              class_time_text: formateDate(
                "YYYY/mm/dd HH:MM",
                Number(value.date) + Number(value.start_time) * 1000
              ), // 上课时间 字符串
              course_duration: "", // 课程时长
              class_time_start:
                Number(value.date) + Number(value.start_time) * 1000, // 上课时间 时间戳毫秒
              class_time_end:
                Number(value.date) + Number(value.end_time) * 1000, // 下课时间 时间戳毫秒
              now_time: new Date().getTime(), // 当前时间
              // stu_num: '111', // 学生人数
              // 课程状态： 0 未上课  1 上课中  2 教学报告
              status: this.isCurrStatus(
                Number(value.date) + Number(value.start_time) * 1000,
                Number(value.date) + Number(value.end_time) * 1000,
                new Date().getTime()
              ),
            };

            if (
              this.idList.indexOf(value.id) == -1 &&
              value.platform == 1 &&
              value.talk_cloud_info != null
            ) {
              // 日历点点
              this.arr.push(obj);
              // 所有班课卡片数据数组
              this.temp.push(dataObj);
              // 把每个课程id存起来校验去重条件
              this.idList.push(value.id);
              // 所有课程日期
              this.dateList.push(dataObj.belonging_day);
            }
          }
          this.tableList = this.temp;
        }
      });
    },
    // 获取班课接口
    getClassRoom() {
      getClassRoom(this.classClass).then((res) => {
        let resData = res.data;
        if (resData.code === 0) {
          let data = resData.data;
          // 请求答疑课
          this.getanswering();
          // let CardArr = []
          for (let value of data) {
            let obj = {
              date: value.attends_date,
              className: "mark1",
            };

            // 列表数据处理，传给卡片组件
            let dataObj = {
              id: value.id,
              belonging_day: value.attends_date, // 所属哪天用于判断是否显示 YYYY-mm-dd
              name: value.name, // 课程名称
              course_name: value.course_name, // 所属课程
              serial: value.talk_cloud_info?.serial, // 拓客云的serial 用于去上课获取上课地址
              teacher_pwd: value.talk_cloud_info?.teacher_pwd,
              assistant_pwd: value.talk_cloud_info?.assistant_pwd,
              patrol_pwd: value.talk_cloud_info?.patrol_pwd,
              class_time_text: `${this.format(3, value.attends_date)} ${
                value.start_text
              }`, // 上课时间 字符串
              course_duration: "", // 课程时长
              class_time_start:
                (Number(value.attends_date_time) + Number(value.start_time)) *
                1000, // 上课时间 时间戳毫秒
              class_time_end:
                (Number(value.attends_date_time) + Number(value.end_time)) *
                1000, // 下课时间 时间戳毫秒
              now_time: new Date().getTime(), // 当前时间
              // stu_num: '111', // 学生人数
              // 课程状态： 0 未上课  1 上课中  2 教学报告
              status: this.isCurrStatus(
                (Number(value.attends_date_time) + Number(value.start_time)) *
                  1000,
                (Number(value.attends_date_time) + Number(value.end_time)) *
                  1000,
                new Date().getTime()
              ),
            };
            if (
              this.idList.indexOf(value.id) == -1 &&
              value.platform == 1 &&
              value.talk_cloud_info != null
            ) {
              // 日历添加点
              this.arr.push(obj);
              // 所有班课卡片数据数组
              this.temp.push(dataObj);
              // 把每个课程id存起来校验去重条件
              this.idList.push(value.id);
              // 所有课程日期
              this.dateList.push(dataObj.belonging_day);
            }
          }
          // this.temp = CardArr
        }
      });
    },
    // 判断课程状态
    isCurrStatus(start, end, now) {
      // 当前时间小于开始时间  未开课
      if (now < start) {
        return 0;
      } else if (start < now && now < end) {
        // 当前时间大于开始时间 并且 小于结束时间  开课中
        return 1;
      } else if (end < now) {
        // 当前时间大于结束时间  已结课
        return 2;
      }
    },
    // 切换日期请求接口
    tabGetPost(date) {
      let time = date || new Date().getTime();
      // 班课接口开始时间YYYY-MM-DD
      let start_date = this.format(1, time);
      // 班课结束时间 YYYY-MM-DD
      let end_date = this.format(2, time);
      this.classClass.start_date = start_date;
      this.classClass.end_date = end_date;
      // 请求班课接口
      // this.getClassRoom();
      // 答疑课开始结束时间 毫秒时间戳
      this.answering.start_date = new Date(start_date).getTime();
      this.answering.end_date = new Date(end_date).getTime();
      this.getDataList();
    },
    // 格式化日期 type = 0 格式化日期  type = 1 上个月日期   type = 2 下个月日期 type = 3  格式化日期 2021/09/01
    format(type, date) {
      date = new Date(date);
      if (type == 0) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      }
      if (type == 3) {
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      }
      // 上月20号
      if (type == 1) {
        // 当前时间戳
        let date1 = new Date(date); // 当前日期
        // 当前一号时间戳
        let timestamp = new Date(
          `${date1.getFullYear()}-${date1.getMonth() + 1}-01`
        ).getTime();
        // 上月最后七天时间戳
        let resTimestamp = timestamp - 1000 * 60 * 60 * 24 * 7;
        let resDate = new Date(resTimestamp);
        return `${resDate.getFullYear()}-${
          resDate.getMonth() + 1
        }-${resDate.getDate()}`;
      }
      // 下月 7号
      if (type == 2) {
        let endDate = new Date(date); // 当前日期
        let currentMonth = endDate.getMonth();
        let nextMonth = ++currentMonth;
        let nextMonthFirstDay = new Date(endDate.getFullYear(), nextMonth, 1);
        let oneDay = 1000 * 60 * 60 * 24;
        let lastTime = new Date(nextMonthFirstDay - oneDay);
        let endMonth = parseInt(lastTime.getMonth() + 1);
        let endDay = lastTime.getDate();
        if (endMonth < 10) {
          endMonth = "0" + endMonth;
        }
        if (endDay < 10) {
          endDay = "0" + endDay;
        }
        // 本月最后一天字符串 2021-09-30
        let resTxet = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
        let timestamp = new Date(resTxet);
        let resTimestamp = Number(timestamp) + 1000 * 60 * 60 * 24 * 7;
        let resDate = new Date(resTimestamp);
        return `${resDate.getFullYear()}-${
          resDate.getMonth() + 1
        }-${resDate.getDate()}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;
  padding: 24px;
  background: #f4f4f7;
  display: flex;
  // 日历相关样式
  .calender-box {
    width: 360px;
    height: 100%;
    background: #fff;
    // 今天
    .calendar-today {
      width: 138px;
      height: 40px;
      border-radius: 8px;
      text-align: center;
      line-height: 40px;
      color: #333333;
      border: 1px solid #333333;
      margin: 0 auto;
      margin-top: 50px;
      cursor: pointer;
    }
  }
  .admin-cantainer {
    flex: 1;
    overflow-y: auto;
    // margin-left: 24px;
    padding-left: 24px;
    position: relative;
    .empty-status {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.card-box {
  margin-bottom: 16px;
  // &:last-child{
  //   margin-bottom: 0;
  // }
}

/deep/ .filter-form {
  height: 32px;
  line-height: 32px;
  margin-bottom: 20px;
  .el-form-item {
    height: 32px;
    margin-right: 20px;
  }
  &-week {
    line-height: 32px;
    height: 32px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  .el-input--suffix {
    height: 32px;
  }
  .el-form-item__content {
    line-height: 32px;
  }
  &-status {
    height: 32px;
    width: 154px;
    font-size: 14px;
    font-weight: 400;

    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
    }
    .el-input__suffix {
      height: 32px;
    }
    .el-input__icon {
      line-height: 32px;
    }
  }
  &-btn {
    height: 32px;
    background: #26272b;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    border-radius: 2px;
    padding: 5px 21px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
/deep/ .el-select-dropdown__item.selected {
  color: #3a3a3a;
  font-family: PingFangSC-Medium, PingFang SC, serif;
}
/deep/ .el-select-dropdown__item {
  color: #999999;
  font-weight: 100;
  font-family: PingFangSC-Regular, PingFang SC, serif;
}
/deep/ .el-popper.el-select-dropdown {
  left: 0 !important;
}
/deep/.el-select .el-input.is-focus .el-input__inner {
  border-color: #393939;
}
</style>
